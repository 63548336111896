// Auth
import React from 'react';
import styles from './Input.module.sass';
import cn from 'classnames';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  customLabel?: React.ReactNode;
  error?: string;
  className?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  iconInput?: boolean;
  icon?: React.ReactNode;
  showErrorText?: boolean;
}

const Input: React.FC<InputProps> = ({
  label,
  customLabel,
  className,
  wrapperClassName,
  labelClassName,
  error,
  iconInput,
  icon,
  showErrorText,
  ...inputProps
}) => {
  const inputClassName = cn('form-input', className);
  const showError = showErrorText && typeof error !== 'undefined';

  return (
    <div
      className={cn('field-wrapper', wrapperClassName, {
        [styles.error]: typeof error !== 'undefined',
      })}
    >
      {label && (
        <label className={cn('field-label', labelClassName)} htmlFor={inputProps.id}>{label}</label>
      )}
      {customLabel}
      {iconInput ? (
        <span className={styles.iconInput}>
          {icon}
          <input {...inputProps} className={inputClassName} />
        </span>
      ) : (
        <input {...inputProps} className={inputClassName} />
      )}
      {showError && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};

export default Input;
